import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { Logo } from '@finn/design-system/icons/logo';
import { cn } from '@finn/ui-utils';
import get from 'lodash/get';
import { useState } from 'react';

import { Action } from './Action';
import {
  ComparisonTableItemBaseValue,
  ComparisonTableMetadata,
  ComparisonTableMetadataDimension,
  ComparisonTableType,
} from './ComparisonSection';
import { Price } from './Price';
import { TableCellData } from './TableCellData';

export const ComparisonAccordion = ({
  data,
  isLoading,
  onAddToCart,
}: {
  data: ComparisonTableType;
  isLoading: boolean;
  onAddToCart: () => void;
}) => {
  const { metadata = {} as ComparisonTableMetadata, items = [] } = data;

  const [openedItems, setOpenedItems] = useState(['featured']);

  return (
    <Accordion
      type="multiple"
      value={openedItems}
      onValueChange={(val) => {
        setOpenedItems(val);
      }}
    >
      {items.map((item, idx) => (
        <AccordionItem
          key={idx}
          value={item.featured ? 'featured' : `regular-${idx}`}
        >
          <AccordionTrigger
            aria-label="FINN"
            className={cn('px-2', { 'bg-black fill-white': item.featured })}
          >
            <div className="flex min-h-6 items-center gap-2">
              {item.featured ? (
                <>
                  {item.header.price ? (
                    <Price
                      price={item.header.price}
                      className="mt-2 flex items-baseline"
                    />
                  ) : (
                    <Logo className="fill-white" />
                  )}
                </>
              ) : (
                <span className="global-t5-semibold">{item.header.title}</span>
              )}
            </div>
          </AccordionTrigger>
          <AccordionContent
            className={cn('px-2', { 'bg-black text-white': item.featured })}
          >
            <ul className="p-0">
              {metadata.dimensions.map(
                (dimension: ComparisonTableMetadataDimension, dIdx: number) => (
                  <li key={dIdx} className="flex items-center px-0 py-3">
                    <TableCellData
                      featured={item.featured}
                      data={
                        get(
                          item,
                          `values.${get(dimension, 'id', '')}`,
                          {}
                        ) as ComparisonTableItemBaseValue
                      }
                    />
                    <span
                      className={cn('global-t6-semibold ml-5', {
                        'text-black': !item.featured,
                        'text-white': item.featured,
                      })}
                    >
                      {dimension.name}
                    </span>
                  </li>
                )
              )}
            </ul>
            {item.footer?.price && (
              <Price
                price={item.footer.price}
                className="mt-2 flex items-baseline"
              />
            )}
            {item.footer?.action && (
              <div className="mt-4">
                <Action
                  action={item.footer.action}
                  fullWidth
                  isLoading={isLoading}
                  onAddToCart={onAddToCart}
                />
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
